<template>
    <div class="Answer">
        <!-- <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
        <img @click="goback" src="../../../../static/common/logo.png" alt="" />
      </div> -->
        <div class="content">
            <div class="banner">
                <div class="dataBox">
                    <!-- <p>
              <span>2023年6月15日</span>
              <img src="/subject/answer_8654/yellow/_.png" alt="">
              <span>2023年7月30日</span>
            </p> -->
                    <img class="dataBottom" src="/subject/answer_8654/yellow/dataBottom.png" alt="">
                </div>
                <img class="titImg" src="/subject/answer_8654/yellow/tit.png" alt="">
                <div class="textBox" v-if="step != 3">
                    <p v-if="sessionAccount">
                        共
                        <span>
                            {{ questionList !== null ? questionList.length : periodtopicnum }}
                        </span>
                        道题，答对
                        <span v-if="questionList !== null && questionList.length > 5 || periodtopicnum > 5">
                            {{ questionList !== null ? questionList.length - 3 : periodtopicnum - 3 }}
                        </span>
                        <span v-else>
                            <span v-if="questionList !== null">
                                {{ questionList.length == 0 ? 0 : questionList.length - 3 }}
                            </span>
                            <span v-else>
                                {{ periodtopicnum == 0 ? 0 : periodtopicnum - 3 }}
                            </span>
                        </span>
                        道，&nbsp;前<span>1000</span>位客户，<br />
                    </p>
                    <p v-else>
                        共<span>10</span>道题，答对<span>7</span>道，&nbsp;前<span>1000</span>位客户，
                    </p>
                    <p>
                        可获得100积分赠送 &nbsp;&nbsp; 先到先得！
                    </p>
                </div>
            </div>
            <div class="mainBody">
                <img class="topicImg" :src="step != 2 ? '/subject/answer_8654/yellow/topic.png' : '/subject/answer_8654/yellow/topic2.png'" alt="">
                <div v-if="step === 0" class="noLogin">
                    <img src="/subject/answer_8654/yellow/book.png" alt="">
                    <span>登录后即可开始答题</span>
                    <p @click="loginDialogShowClick">立即登录答题</p>
                </div>
                <div v-if="step === 1" class="showDivBox">
                    <div class="showDiv" v-for="(item, i) in questionList" :key="item.id">
                        <div v-if="number - 1 === i">
                            <p>{{ i + 1 }}、{{ item.topic }}</p>
                            <ul class="selectBox">
                                <li v-for="(attr, k) in item.answer.split(',')" :key="k"
                                    @click="handleSelect(i + 1, mockData[k])">
                                    <span :class="selected == mockData[k] ? 'seleted' : 'noSeleted'">{{ mockData[k]
                                    }}</span>
                                    <span>{{ item.answer.split(',')[k] }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="buttonBox" v-if="step === 1">
                    <div class="buttonBox_">
                        <span @click="previousQuestion">上一题</span>
                        <span v-if="!lastQ" @click="nextQuestion">下一题</span>
                        <span v-else @click="submitAnswer">提交</span>
                    </div>
                </div>
                <ul class="finally" v-if="step === 2">
                    <li>答对题目：<span> {{ rightNum ? rightNum : '--' }} &nbsp;</span>道</li>
                    <li>答错题目：<span> {{ periodtopicnum && periodtopicnum - rightNum }} &nbsp;</span>道</li>
                    <div class="success" v-if="isSuccess">
                        <li>
                            <img src="/subject/answer_8654/yellow/integral.png" alt="">
                            <span>+ {{ awardnum ? awardnum : '--' }}</span>
                        </li>
                        <li>恭喜通关，获得{{ awardnum ? awardnum : '--' }}积分！</li>
                    </div>
                    <div class="fail" v-else>
                        <li>
                            抱歉，通过失败，继续加油！
                        </li>
                        <!-- <li>返回</li> -->
                    </div>
                </ul>
                <div class="noLogin" v-if="step === 3">
                    当前没有已上架并进行中的活动
                </div>
                <p v-if="step != 0 && step != 2" class="tips">小贴士：答题期间，离开该页面，需要重新开始作答。</p>
            </div>
        </div>
        <div v-if="tagTxt !== null" class="tagBox">
            <h3>提示</h3>
            <p>{{ tagTxt }}</p>
            <div>
                <span v-if="tagTxt.indexOf('正在保存答案') < 0" @click="tagTxt = null">确认</span>
            </div>
        </div>
        <div v-if="tagTxt !== null" class="shadow"></div>
        <loginDialog :loginDialogShow="loginDialogShow" @loginDialogShowChange="loginDialogShowChange_this($event)" />
    </div>
</template>
  
<script>
import { getIssue, getQuestion, submitQuestion, getAnswer, getReward } from '../../../../../api/info';
import { getUserByToken } from '../../../../../api/components/loginDialog';
import { getSessionStorage } from '../../classRoom/utils/tool';
import { getUrlParam } from '../../../../../utils/tools'
import loginDialog from '../../components/loginDialog/wap/loginDialog.vue';
export default {
    name: 'answer_8654_red_wap',
    data() {
        return {
            loginDialogShow: false,
            navBarFixed: false,
            // 用户账号
            sessionAccount: null,
            // 活动id
            answeractivityid: null,
            // 活动期数
            periodid: null,
            // 问题列表
            questionList: null,
            // 记录第几道题
            number: 1,
            // 模拟选项
            mockData: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
            // 控制是否点选
            selected: null,
            // 存储用户答案
            accountAnswer: [],
            // 判断是否最后一题
            lastQ: false,
            // 弹窗提示内容
            tagTxt: null,
            // 当前进行到哪一步
            step: 0,
            // 判断答题成功或失败
            isSuccess: true,
            // 存储答对题目数
            rightNum: 0,
            // 存储用户答题获得的奖励
            awardnum: null,
            // 答完题后从后台获取的题目数
            periodtopicnum: null,
            scroll: '',
            screenWidth: null,
        }
    },
    components: {
        loginDialog
    },
    created() {
        // 传给App.vue,不展示tabbar
        this.$emit('controlShow', true);
        var token_subject = ''
        if (getUrlParam('token_subject')) {
            token_subject = getUrlParam('token_subject')
            getUserByToken(token_subject).then(res => {
                window.sessionStorage.setItem('account', res.data.Account)
                this.init()
            })
        } else if (getUrlParam('accessKey')) {
            token_subject = getUrlParam('accessKey')
            getUserByToken(token_subject).then(res => {
                window.sessionStorage.setItem('account', res.data.Account)
                this.init()
            })
        } else if (window.localStorage.getItem('token_subject')) {
            token_subject = window.localStorage.getItem('token_subject')
            getUserByToken(token_subject).then(res => {
                window.sessionStorage.setItem('account', res.data.Account)
                this.init()
            })
        } else {
            this.init()
        }
    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 500) {
            if (getUrlParam('token_subject')) {
                this.$router.push({
                    name: "answer_8654_yellow_wap",
                    query: { token_subject: getUrlParam('token_subject') }
                });
            } else if (getUrlParam('accessKey')) {
                this.$router.push({
                    name: "answer_8654_yellow_pc",
                    query: { token_subject: getUrlParam('accessKey') }
                });
            } else {
                this.$router.push({
                    name: "answer_8654_yellow_pc"
                });
            }
        }
    },
    methods: {
        loginDialogShowClick() {
            this.loginDialogShow = true
        },
        loginDialogShowChange_this(value) {
            this.loginDialogShow = value
        },
        init() {
            // 登录后再开始逻辑处理
            this.sessionAccount = getSessionStorage('account');
            //if (this.sessionAccount) {
            let option = {
                activityid: 30,
                platform: 1,
                accountid: this.sessionAccount,
            };
            // 查询活动期数
            getIssue(option).then(res => {
                if (res.status === 0 && res.msg !== '当前没有已上架并进行中的活动') {
                    this.answeractivityid = res.answeractivityid;
                    this.periodid = res.periodid;
                    // 查询用户是否答题
                    getAnswer({ AnswerActivityId: this.answeractivityid, Account: this.sessionAccount }).then(res => {
                        if (res.status === 0) {
                            if (res.answeractivityaccounttopics.length == 0) {
                                this.step = 1;
                                this.handleQuestion();
                            } else {
                                this.getRewardFunction();
                                this.step = 2;
                                this.rightNum = res.answeractivityaccounttopics[0].correctnum;
                                this.periodtopicnum = res.answeractivityaccounttopics[0].periodtopicnum;
                            }
                        }
                    })
                } else {
                    this.step = 3;
                }
            })
            //}
        },
        // 查询活动题目
        handleQuestion() {
            getQuestion({ AnswerActivityId: this.answeractivityid }).then(res => {
                if (res.status === 0) {
                    this.questionList = this.dataReset(res.AnswerActivityConfigPeriodTopics);
                }
            })
        },
        // 通过期数id筛选对应期数的题目
        dataReset(data) {
            let list;
            list = data.filter(item => {
                return item.periodid === this.periodid;
            });
            return list
        },
        // 用户选择每道题的选项
        handleSelect(NUM, ABC) {
            this.selected = null;
            if (NUM == this.number) {
                this.selected = ABC;
            }
            let Alist = [...this.accountAnswer];
            if (NUM > Alist.length) {
                Alist.push(ABC);
            } else {
                Alist[NUM - 1] = ABC;
            }
            this.accountAnswer = Alist;
        },
        // 获取用户奖励的回调函数
        getRewardFunction() {
            let attr = {
                Account: this.sessionAccount,
                Platform: 1,
                AnswerActivityId: this.answeractivityid,
                PeriodId: this.periodid,
            }
            // 查询答题活动用户奖励
            getReward(attr).then(res => {
                if (res.status == 0) {
                    if (res.answeractivityaccountawards.length > 0) {
                        this.isSuccess = true;
                        let reward = res.answeractivityaccountawards.filter(item => {
                            return item.periodid == this.periodid && item.accountid !== 0;
                        })
                        this.awardnum = reward[0].awardnum;
                    } else {
                        this.isSuccess = false;
                    }
                }
            })
        },
        // 用户点击下一题
        nextQuestion() {
            this.sessionAccount = getSessionStorage('account');
            if (this.sessionAccount == null || this.sessionAccount == undefined) {
                return this.loginDialogShow = true
            }
            if (this.selected == null || this.selected == undefined) {
                return this.$message.error('题目未作答')
            }
            // 最后一题的时候显示提交按钮
            if (this.number == this.questionList.length - 1) {
                this.lastQ = true;
            }
            // 通过number控制题目显示
            if (this.number < this.questionList.length) {
                this.number++
            } else {
                return
            }
            // 拿到用户下一题的答案
            this.selected = this.accountAnswer[this.number - 1];
        },
        // 用户点击上一题
        previousQuestion() {
            // 非最后一题的时候显示下一题按钮
            if (this.number !== this.questionList.length - 1) {
                this.lastQ = false;
            }
            // 通过number控制题目显示
            if (this.number > 1) {
                this.number--;
            } else {
                return
            }
            // 拿到用户上一题的答案
            this.selected = this.accountAnswer[this.number - 1];
        },
        // 提交答案
        submitAnswer() {
            if (this.accountAnswer.length !== this.questionList.length) {
                this.tagTxt = '您有问题尚未回答，请先回答！';
            } else {
                let params = {
                    AnswerActivityId: this.answeractivityid,
                    PeriodId: this.periodid,
                    Account: this.sessionAccount,
                    AccountAnswer: this.accountAnswer.join(','),
                }
                submitQuestion(params).then(res => {
                    if (res.status == 0) {
                        var time = 10
                        var _this = this
                        setInterval(function () {
                            _this.tagTxt = '已完成答题,正在保存答案 (' + time + 's)';
                            time = time - 1
                        }, 1000)
                        this.rightNum = res.answeractivityaccounttopic.correctnum;
                        setTimeout(() => {
                            location.reload();
                        }, 10000)
                    }
                })
            }
        },
        // 返回上级页面
        goback() {
            window.location.href = window.location.origin;
        },
        // 控制顶部的定位
        handleScroll() {
            this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
            if (this.scroll > 90) {
                this.navBarFixed = true;
            } else {
                this.navBarFixed = false;
            }
        },
    }
}
</script>
  
<style lang="less" scoped>
.Answer {
    width: 100%;
    height: auto;
    background: url(/subject/answer_8654/yellow/wap/background.png) no-repeat;
    background-size: 100% 100%;

    .tabbar {
        width: 100%;
        background-color: #ffffff;

        img {
            margin: 10px 30px;
            width: 130px;
        }
    }

    .navBarFixed {
        position: fixed;
        top: 0;
        z-index: 999;
    }

    .content {
        width: 100%;
        height: auto;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 15px;

        .banner {
            width: 100%;
            height: 240px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            .dataBox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: #feffff;
                        font-size: 18px;
                    }
                }

                .dataBottom {
                    width: 85%;
                }
            }

            .textBox {
                width: 310px;
                height: 68px;
                background: url('/subject/answer_8654/yellow/textBox.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    margin: 2px auto;
                    color: #feffff;
                    font-size: 15px;

                    span {
                        color: #fcea48;
                        font-weight: 600;
                        margin: 0 3px;
                    }

                    span:nth-child(3) {
                        color: #feffff;
                    }
                }
            }

            .titImg {
                width: 82%;
                margin: 15px auto;
            }
        }

        .mainBody {
            width: 100%;
            height: 430px;
            border-radius: 6px;
            margin: 100px auto 0;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;

            .topicImg {
                width: 117px;
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .noLogin {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    color: #666666;
                    margin-top: 10px;
                }

                p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    background-color: #ffcb16;
                    width: 200px;
                    height: 45px;
                    border-radius: 25px;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: 600;
                    position: absolute;
                    bottom: 30px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }

            .showDivBox {
                width: 85%;

                .showDiv {
                    width: 100%;
                    display: block;
                }
            }

            .topicP {
                font-weight: 600;
                color: #1c1d1f;
            }

            .selectBox {
                list-style: none;
                padding-left: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                margin: 15px auto;

                li {
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline;
                    line-height: 25px;
                    cursor: pointer;
                    font-size: 14px;
                    margin-bottom: 15px;

                    &:nth-child(4) {
                        margin-bottom: 0;
                    }

                    span:nth-child(1) {
                        flex-shrink: 0;
                        box-sizing: border-box;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                        margin-right: 10px;
                    }

                    .seleted {
                        background-color: #fdda5f;
                        border: none;
                        color: #fff;
                    }

                    .noSeleted {
                        border: 1px solid #dfdfdf;
                        color: #9ba2b5;
                    }
                }
            }

            .buttonBox {
                width: 100%;

                .buttonBox_ {
                    width: 85%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 auto;

                    span {
                        width: 135px;
                        height: 40px;
                        border: 1px solid #ffcb16;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 6px;
                        color: #ffcb16;
                        cursor: pointer;
                    }

                    span:nth-child(2) {
                        color: #fff;
                        background-color: #ffcb16;
                    }
                }
            }

            .tips {
                width: 100%;
                position: absolute;
                bottom: 8px;
                left: 0;
                text-align: center;
                color: #666666;
                font-size: 13px;
            }

            .finally {
                list-style: none;
                padding-left: 0;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #1d1d1f;
                    font-weight: 600;
                    margin: 20px auto;

                    img {
                        width: 62px;
                    }
                }

                li:nth-child(1) {
                    span {
                        color: #25cb5b;
                    }
                }

                li:nth-child(2) {
                    span {
                        color: #fb662e;
                    }
                }

                .success {
                    li:nth-child(1) {
                        span {
                            color: #ffa20e;
                        }
                    }

                    li:nth-child(2) {
                        color: #ffcb16;
                        font-size: 18px;
                        text-align: center;
                    }

                    // li:last-child {
                    //   display: flex;
                    //   justify-content: center;
                    //   align-items: center;
                    //   color: #fff;
                    //   background-color: #ffcb16;
                    //   width: 160px;
                    //   height: 38px;
                    //   border-radius: 20px;
                    //   cursor: pointer;
                    // }
                }

                .fail {
                    margin-top: 30px;

                    li:nth-child(1) {
                        margin-bottom: 30px;
                        color: #a3a3a3;
                    }

                    li:nth-child(2) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        background-color: #ffcb16;
                        width: 160px;
                        height: 38px;
                        border-radius: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .shadow {
        width: 100%;
        height: 100%;
        background-color: rgba(33, 33, 33, 0.3);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .tagBox {
        position: fixed;
        width: 95%;
        height: 200px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-top: 10px;
        z-index: 1;

        p {
            margin: 0 auto 10px;
        }

        div {
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            span {
                width: 140px;
                height: 40px;
                border-radius: 5px;
                border: 1px solid #ffcb16;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffcb16;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}</style>